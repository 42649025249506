@import url("../../styles/global.less");

.layout {
  height: 100%;

  .topHeader {
    height: @header-height;
    background-color: #001628;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;

    .leftHeader {
      display: flex;
      align-items: center;

      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          cursor: pointer;
          height: 32px;
        }
        p {
          display: inline;
          color: #fff;
          font-size: 1.5em;
          font-weight: 800;
          margin: 0px 10px;
          span {
            font-size: 0.6em;
            font-weight: 400;
          }
        }
      }

      .planInfomation {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        background-color: #e79a0b3a;
        border: 2px solid sandybrown;
        height: 30px;
        width: 90px;
        border-radius: 4px;
        
        p {
          margin: 0px 10px;
          color: #ebecf0;
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    .profileNav {
      width: 100%;
      z-index: 2;
    }
    :global {
      .ant-popover-inner-content {
        padding: 8px 0;
        width: 100%;
      }
    }
    .menuOptions {
      .option {
        padding: 8px 10px 8px 20px;
        cursor: pointer;

        i {
          margin-right: 10px;
          text-align: left;
        }
      }
      .option:hover {
        background-color: #f3f3f3;
      }
    }
    .userPopoverTitle {
      display: flex;
      align-items: center;
      padding: 10px 5px;

      .userImage {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        margin-right: 14px;
      }
      
      .userEmail {
        color: #6b778c;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .profileImage {
      height: 34px;
      width: 34px;
      border-radius: 50%;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 5px;
      cursor: pointer;
    }
  }
  .navBar {
    background-color: #f4f5f7;
    border-right: 1px solid #e0e0e0;
    box-shadow: 0 0 2px rgb(0 0 0);
    -webkit-box-shadow: 0 0 3px #d3d3d3;
    > div {
      display: flex;
      flex-direction: column;
    }
    .logo {
      padding: 30px;
      display: flex;
      align-items: center;
      font-family: Roboto;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 1px;
      cursor: pointer;
      img {
        height: auto;
        width: 150px;
        margin-right: 6px;
      }
      span {
        margin-top: 10px;
      }
    }
    .navSection {
      padding-top: 20px;
      overflow-y: auto;
      .nav {
        .navIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
        }
        .navName {
          font-size: 10px;
          text-align: center;
          line-height: 32px;
        }
      }
      :global {
        .ant-menu {
          background-color: #f4f5f7;
          // font-size: 15px;
        }
        .ant-menu-item {
          height: 80px;
          padding: 0px !important;
          margin-bottom: 0px;
        }
        // .ant-menu-item {
        //   font-weight: 500;
        //   height: 50px;
        //   display: flex;
        //   align-items: center;

        //   i {
        //     margin-top: 1px;
        //     margin-right: 12px;
        //   }
        // }
        .ant-menu-item::after {
          border-right: none;
        }
        .ant-menu-item-selected {
          background-color: #ebecf0;
          color: #0967d2;
          font-weight: 600;
        }
      }
    }
  }
  .mainScreen {
    height: calc(100% - @header-height);
    overflow-y: auto;
    background-color: white;
  }
}
@media (max-width: 760px) {
  .layout {
    .navBar {
      position: absolute;
      height: 100%;
      z-index: 10;
    }
    .mainScreen {
      padding: 20px 50px;
    }
  }
}
