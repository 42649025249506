@import url("./styles/global.less");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #root {
    height: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-input,
.ant-select-selector,
.ant-btn {
  border-radius: 5px;
}

// @media (-webkit-min-device-pixel-ratio: 0.8) {
//   #root {
//     zoom: 1/1.3;
//   }
// }

// @media (-webkit-min-device-pixel-ratio: 1.25) {
//   #root {
//     zoom: 1/1.25;
//   }
// }

// @media (-webkit-min-device-pixel-ratio: 1.5) {
//   #root {
//     zoom: 1/1.2;
//   }
// }

// @media (-webkit-min-device-pixel-ratio: 1.75) {
//   #root {
//     zoom: 1;
//   }
// }
